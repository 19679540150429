export const ID_LEVEL = {
  UT_DAERAH: 3,
  SA_DSI: 1,
  OPERATOR: 2
}

export const ID_UNIT_KS = {
  DSI: ['119'],
  DAAK: ['115'],
  REKTORAT: ['117', '125'],
  KEUANGAN: ['123'],
  DPKS: ['375'],
  FAKULTAS: [
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '60',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '68',
    '69',
    '70',
    '71',
    '72',
    '73',
    '74',
    '75',
    '76',
    '77',
    '78',
    '79',
    '80',
    '81',
    '82',
    '83',
    '84',
    '85',
    '86',
    '87',
    '88',
    '89',
    '90',
    '91',
    '92',
    '93',
    '94',
    '95',
    '96',
    '97',
    '98',
    '99',
    '101',
    '102',
    '103',
    '104',
    '105',
    '106',
    '107',
    '108',
    '109',
    '110',
    '111',
    '112',
    '113'
  ],
  UT_DAERAH: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '100'
  ]
}

export const ID_GROUP = {
  PUSKOM: ['4', '49', '50'],
  UPBJJ_ADMIN: ['38'],
  UPBJJ: ['38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48'],
  WR4: ['28', '33', '77'],
  DPKS: ['110', '111', '112'],
  /** TODO: Pastikan  Role DAAK Kelulusan */
  DAAK_BEASISWA: ['7'],
  DAAK: ['6', '7', '8', '9', '12', '52', '53'],
  DAAK_KELULUSAN: ['12', '52', '53'],
  /** DAAK Registrasi */
  DAAK_SPV: ['6', '7'],
  DAAK_OPERATOR: ['8', '9'],
  PBB: ['34', '37', '79'],
  PUSJIAN: ['1', '2', '3'],
  PPS: ['36'],
  PJ_KEUANGAN_PKS: ['29'],
  FAKULTAS: [
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '60',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '68',
    '69',
    '70',
    '71',
    '72',
    '73',
    '74',
    '75',
    '76'
  ]
}

export const APP_MODE = {
  PRODUCTION: 'production'
}

const KODE_UPBJJ = {
  MEDAN: '12',
  PADANG: '14',
  PALEMBANG: '18',
  BOGOR: '23',
  SEMARANG: '42',
  YOGYAKARTA: '45',
  PONTIANAK: '47',
  SURABAYA: '71',
  SAMARINDA: '50',
  MALANG: '74',
  JEMBER: '76',
  SERANG: '22'
}

/**
 * Upbjj yang validasi sendiri
 */
export const KODE_UPBJJ_VERVAL = [
  KODE_UPBJJ.SAMARINDA,
  KODE_UPBJJ.MALANG,
  KODE_UPBJJ.MEDAN,
  KODE_UPBJJ.JEMBER,
  KODE_UPBJJ.SEMARANG,
  KODE_UPBJJ.PADANG,
  KODE_UPBJJ.PONTIANAK,
  KODE_UPBJJ.BOGOR,
  KODE_UPBJJ.PALEMBANG,
  KODE_UPBJJ.YOGYAKARTA
]

export const STATUS_BERKAS = {
  GAGAL: 5,
  BERHASIL: 0,
  BARU_UPLOAD: 1,
  PROSES_VALIDASI: 9
}
