import { ID_UNIT_KS } from '@/types/index'

export const STATUS = {
  PROD: 'production',
  DEV: 'development'
}

export const FEATURE_STATUS = {
  DAAK_DAN_KEUANGAN: STATUS.PROD,
  IN_DEVELOPMENT: STATUS.DEV,
  /**
   * MASTER
   */
  MASTER_JENIS_PKS: STATUS.PROD,
  MASTER_GROUP_MITRA: STATUS.PROD,
  MASTER_KATEGORI_PKS: STATUS.PROD,
  MASTER_SUB_KATEGORI_PKS: STATUS.PROD,
  /**
   * KERJASAMA
   */
  KERJASAMA_ENTRY_BEASISWA: STATUS.PROD,
  KERJASAMA_IMPORT_BEASISWA: STATUS.PROD,
  KERJASAMA_SURAT_TAGIHAN: STATUS.PROD,
  KERJASAMA_DOKUMEN_SK: STATUS.PROD,
  KERJASAMA_SERBAGUNA_BILLING_SATUAN: STATUS.PROD,
  KERJASAMA_SERBAGUNA_BILLING_MASSAL: STATUS.PROD,
  KERJASAMA_FLAGGING_BILLING_SATUAN: STATUS.PROD,
  KERJASAMA_FLAGGING_BILLING_MASSAL: STATUS.PROD,
  /**
   * MENU KHUSUS
   */
  MENU_KHUSUS_AKTIVASI_BILLING_SATUAN: STATUS.PROD,
  MENU_KHUSUS_AKTIVASI_BILLING_MASSAL: STATUS.PROD,
  MENU_KHUSUS_VERIFIKASI_DOKUMEN: STATUS.PROD,
  MENU_KHUSUS_PEMBATALAN_VERIFIKASI: STATUS.PROD,
  MENU_KHUSUS_MANAJEMEN_USER: STATUS.DEV
}

export const MENU_ROLE = {
  /** ID_UNIT_KS */
  IN_DEVELOPMENT: [...ID_UNIT_KS.DSI],
  DAAK_DAN_KEUANGAN: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.DAAK,
    ...ID_UNIT_KS.KEUANGAN,
    ...ID_UNIT_KS.DPKS
  ],
  FULL_ACCESS: [...ID_UNIT_KS.DSI, ...ID_UNIT_KS.REKTORAT, ...ID_UNIT_KS.DPKS],
  DPKS: [...ID_UNIT_KS.DSI, ...ID_UNIT_KS.REKTORAT, ...ID_UNIT_KS.DPKS],
  SRUH: [...ID_UNIT_KS.DSI, ...ID_UNIT_KS.REKTORAT, ...ID_UNIT_KS.DPKS],
  UT_DAERAH: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.UT_DAERAH,
    ...ID_UNIT_KS.DPKS
  ],
  DAAK: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.DAAK,
    ...ID_UNIT_KS.DPKS
  ],
  KEUANGAN: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.KEUANGAN,
    ...ID_UNIT_KS.DPKS
  ],
  PPBI: [...ID_UNIT_KS.DSI, ...ID_UNIT_KS.REKTORAT, ...ID_UNIT_KS.DPKS],
  /** ID_GROUP */
  // IN_DEVELOPMENT: [...ID_GROUP.PUSKOM],
  // DAAK_DAN_KEUANGAN: [
  //   ...ID_GROUP.PUSKOM,
  //   ...ID_GROUP.WR4,
  //   ...ID_GROUP.DAAK,
  //   ...ID_GROUP.PJ_KEUANGAN_PKS,
  //   ...ID_GROUP.DPKS
  // ],
  // DPKS: [
  //   ...ID_GROUP.PUSKOM,
  //   ...ID_GROUP.WR4,
  //   ...ID_GROUP.DPKS
  // ],
  // SRUH: [
  //   ...ID_GROUP.PUSKOM,
  //   ...ID_GROUP.WR4,
  //   ...ID_GROUP.DPKS
  // ],
  // UT_DAERAH: [
  //   ...ID_GROUP.PUSKOM,
  //   ...ID_GROUP.WR4,
  //   ...ID_GROUP.UPBJJ,
  //   ...ID_GROUP.DPKS
  // ],
  // DAAK: [
  //   ...ID_GROUP.PUSKOM,
  //   ...ID_GROUP.WR4,
  //   ...ID_GROUP.DAAK,
  //   ...ID_GROUP.DPKS
  // ],
  // KEUANGAN: [
  //   ...ID_GROUP.PUSKOM,
  //   ...ID_GROUP.WR4,
  //   ...ID_GROUP.PJ_KEUANGAN_PKS,
  //   ...ID_GROUP.DPKS
  // ],
  // PPBI: [
  //   ...ID_GROUP.PUSKOM,
  //   ...ID_GROUP.WR4,
  //   ...ID_GROUP.DPKS
  // ],

  // DPKS_SRUH: [
  //   ...ID_GROUP.PUSKOM,
  //   ...ID_GROUP.WR4,
  //   ...ID_GROUP.DPKS
  // ],
  // UT_DAERAH_DAAK: [
  //   ...ID_GROUP.PUSKOM,
  //   ...ID_GROUP.WR4,
  //   ...ID_GROUP.UPBJJ,
  //   ...ID_GROUP.DAAK
  // ],
  // KEUANGAN_UT_DAERAH_PPBI: [
  //   ...ID_GROUP.PUSKOM,
  //   ...ID_GROUP.WR4,
  //   ...ID_GROUP.PJ_KEUANGAN_PKS,
  //   ...ID_GROUP.UPBJJ
  // ],
  /** Menu */
  /**
   * MASTER
   */
  MASTER_JENIS_PKS: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.DPKS
  ],
  MASTER_GROUP_MITRA: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.DPKS
  ],
  MASTER_KATEGORI_PKS: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.DPKS
  ],
  MASTER_SUB_KATEGORI_PKS: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.DPKS
  ],
  /**
   * KERJASAMA
   */
  KERJASAMA_ENTRY_BEASISWA: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.UT_DAERAH,
    ...ID_UNIT_KS.DAAK,
    ...ID_UNIT_KS.DPKS
  ],
  KERJASAMA_IMPORT_BEASISWA: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.UT_DAERAH,
    ...ID_UNIT_KS.DAAK,
    ...ID_UNIT_KS.DPKS
  ],
  KERJASAMA_SURAT_TAGIHAN: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.KEUANGAN,
    ...ID_UNIT_KS.UT_DAERAH,
    ...ID_UNIT_KS.DPKS
  ],
  KERJASAMA_DOKUMEN_SK: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.DAAK,
    ...ID_UNIT_KS.DPKS
  ],
  KERJASAMA_SERBAGUNA_BILLING_SATUAN: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.DAAK,
    ...ID_UNIT_KS.UT_DAERAH,
    ...ID_UNIT_KS.DPKS
  ],
  KERJASAMA_SERBAGUNA_BILLING_MASSAL: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.DAAK,
    ...ID_UNIT_KS.UT_DAERAH,
    ...ID_UNIT_KS.DPKS
  ],
  KERJASAMA_FLAGGING_BILLING_SATUAN: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.DAAK,
    ...ID_UNIT_KS.UT_DAERAH,
    ...ID_UNIT_KS.DPKS
  ],
  KERJASAMA_FLAGGING_BILLING_MASSAL: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.DAAK,
    ...ID_UNIT_KS.UT_DAERAH,
    ...ID_UNIT_KS.DPKS
  ],
  /**
   * MENU KHUSUS
   */
  MENU_KHUSUS_AKTIVASI_BILLING_SATUAN: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.DAAK,
    ...ID_UNIT_KS.UT_DAERAH,
    ...ID_UNIT_KS.DPKS
  ],
  MENU_KHUSUS_AKTIVASI_BILLING_MASSAL: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.DAAK,
    ...ID_UNIT_KS.UT_DAERAH,
    ...ID_UNIT_KS.DPKS
  ],
  MENU_KHUSUS_VERIFIKASI_DOKUMEN: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.DPKS
  ],
  MENU_KHUSUS_PEMBATALAN_VERIFIKASI: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.DPKS
  ],
  MENU_KHUSUS_MANAJEMEN_USER: [
    ...ID_UNIT_KS.DSI,
    ...ID_UNIT_KS.REKTORAT,
    ...ID_UNIT_KS.DPKS
  ]
}
